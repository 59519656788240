@import 'mixins';
@import 'palette';

.liquid-light-theme {
    .mat-accordion {
        &.filter-accordion {
            border-radius: 8px;
            max-height: 500px;
            overflow-y: auto;
            @include xs {
                max-width: 300px !important;
            }
            .mat-expansion-panel {
                box-shadow: none;
                &:first-of-type {
                    .mat-expansion-panel-header {
                        border-top-color: transparent;
                    }
                }
                &.no-header {
                    border-top-left-radius: 8px;
                    border-top-right-radius: 8px;
                    .mat-expansion-panel-body {
                        border-top-left-radius: 8px;
                        border-top-right-radius: 8px;
                    }
                }
            }
            .mat-expansion-panel-spacing {
                margin: 0;
            }
            .mat-expansion-panel-header {
                height: 60px !important;
                padding: 0 15px;
                border-radius: 0;
                border-top: 1px solid $black20;
                border-bottom: 1px solid transparent;
                &.mat-expanded {
                    border-bottom-color: $black20;
                }
                .mat-expansion-panel-header-title {
                    align-items: center;
                    justify-content: space-between;
                    color: $primary100;
                }
            }
            .mat-expansion-panel-body {
                padding: 14px 8px;
                display: flex;
                flex-direction: column;
                .clear {
                    width: auto !important;
                    .mat-button-wrapper {
                        flex: 1;
                        justify-content: space-between;
                    }
                }
                .option-wrapper {
                    margin: 4px 0;
                    padding: 8px;
                    .mat-button-wrapper {
                        flex: 1;
                        justify-content: space-between;
                        span.label {
                            flex: 1;
                            text-align: left;
                            margin-left: 6px;
                        }
                    }
                }
            }
            .mat-expansion-panel.title {
                .mat-expansion-panel-body {
                    padding: 4px 4px 4px 16px;
                    flex-direction: row;
                    align-items: center;
                    justify-content: space-between;
                    span.label {
                        line-height: 48px;
                    }
                }
            }
            .mat-expansion-panel.no-header .mat-expansion-panel-body {
                padding-top: 0;
            }
        }
        &.sort-select {
            z-index: 1000;
            max-height: 500px;
            overflow-y: auto !important;
            background-color: $white-default;
            @include drop-shadow;
            .mat-expansion-panel-body {
                padding: 16px;
                button {
                    width: 100%;
                }
            }
        }



        .icon-badge {
            display: flex;
            justify-content: center;
            align-items: center;
            height: 25px;
            width: 25px;
            border-radius: 50%;
            border: solid 3px $primary20;
            float: left;

            i-feather {
                padding: 0px;
                margin: 0px;
                width: 15px;
                height: 15px;
            }
        }


        .group-badge[group-badge] {

            &.active {
                font-weight: bold;

                &:after {
                    border: solid 3px $primary40;
                }
            }

            &:after {
                content: attr(group-badge);
                height: 20px;
                min-width: 20px;
                text-align: center;
                border-radius: 50%;
                border: solid 3px $primary20;
                float: left;
            }
        }

    }

    // Needed for scrolling with overlay cdk
    .cdk-overlay-connected-position-bounding-box {
        overflow-y: auto;
    }

    .workflow-snack {
        border-radius: 8px;
        color: $error-dark;
        background-color: $error-light;
        @include drop-shadow;
        .mat-simple-snackbar {
            // don't want to override other snacks with these until approved
            font-size: 16px;
            line-height: 24px;
            font-weight: bold;
        }
    }
}
