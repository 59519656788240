@import 'palette';

@media print {
    .site-header,
    .sidenav-nav,
    .cdk-overlay-container,
    .hidden-input,
    .mat-select-value-text,
    .shadow,
    .mat-expansion-indicator,
    .help-aside-wrapper,
    .hide-print,
    .fc-widget-small {
        display: none !important;
    }

    .inline-error {
        background-color: $error-light !important;
        -webkit-print-color-adjust: exact !important;
    }

    .inline-success {
        background-color: $success-light !important;
        -webkit-print-color-adjust: exact !important;
    }

    .inline-warning {
        background-color: $warning-light !important;
        -webkit-print-color-adjust: exact !important;
    }

    app-invoice-summary {
        .stack {
            display: flex;
            justify-content: space-between;

            .col.invoice-info {
                max-width: 45%;
                p {
                    text-align: right;
                    -webkit-print-color-adjust: exact !important;
                }
            }
        }

    }

    app-help-tooltip {
        i-feather.trigger {
            display: none;
        }
    }

    .order-detail-card {
        .detail-list {
            .mat-expansion-panel-header {
                padding-top: 0 !important;
                padding-bottom: 0 !important;
            }
        }
        app-deliverable-form {
            form {
                padding: 0 12% !important;
                -webkit-print-color-adjust: exact !important;
            }
        }
    }

    .sidenav-container {
        min-height: 0px !important;
    }

    .liquid-light-theme {
        padding: 10px;
        background: none;

        .mat-chip.mat-standard-chip {
            -webkit-print-color-adjust: exact !important;
        }
    }

    .line-items-table {
        width: 100% !important;
        -webkit-print-color-adjust: exact !important;
    }

    * {
        overflow: unset !important;
        height: auto !important;
        width: auto !important;
        min-height: 0px !important;
        max-height: max-content !important;
    }

    .invoice-detail {
        width: 561px !important;
    }

    .avatar{
        img {
            height: 100px !important;
            width: 100px !important;
        }
        .avatar-placeholder {
            height: 100px !important;
            width: 100px !important;
            background-color: $black80;
            -webkit-print-color-adjust: exact !important;
        }
    }

    .trigger {
        height: 20px !important;
        width: 20px !important;
    }

    .content {
        .sidenav-container {
            background: none !important;
        }
    }

    .summary {
        .detail-list {
            .mat-expansion-panel-content {
                overflow: visible !important;
                display: block !important;
                visibility: visible !important;
                height: inherit !important;
            }
        }
    }
}
