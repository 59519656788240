@import 'mixins';
@import 'palette';

.liquid-light-theme {
    .mat-card {
        border-radius: 6px;
        margin-bottom: 1em;
        flex: 1;

        &:not([class*='mat-elevation-z']) {
            @include drop-shadow;
        }

        .mat-card-header-text {
            flex: 1;
        }

        .mat-card-actions {
            display: flex;
            flex-direction: row;
            justify-content: flex-end;

            button:not(:last-child) {
                margin-right: 8px;
            }
        }

        &.zoom {
            &:hover {
                z-index: 1000;
                transform: scale3d(1.1, 1.1, 1);
            }
        }
    }

    .mat-card-header {
        .mat-card-header-text {
            margin: 0px !important;
        }
    }
}