@import 'palette';

.liquid-light-theme {
    .madlib-error {
        white-space: wrap;
        word-wrap: break-word;
        border-radius: 8px;
        border: 1px solid $primary30;
        color: $primary100;
        font-size: 12px;
        line-height: 16px;
        text-align: center;
        max-width: auto;
        background-color: $white-default;
    }    
}