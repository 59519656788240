@import 'palette';
@import 'breakpoints';

app-workflow-module {

    mat-expansion-panel {
        box-shadow: none !important;
    }

    .workflow-module-top-shadow,
    .workflow-module-bottom-shadow {
        flex: 1;
    }

    .workflow-module-top-shadow {
        height: 20px;
        background: linear-gradient(180deg, rgba(216, 216, 216, 0.45) 0%, rgba(255, 255, 255, 0) 88.84%);
    }

    .workflow-module-panel {
        border-left: 1px solid $black10;
        border-right: 1px solid $black10;
        border-bottom: 1px solid $black10;

        &.mat-expanded {

            .workflow-module-header {
                border-bottom: 1px solid $black10;
            }
        }
    }

    .workflow-group-panel {

        &:not(:last-of-type) {
            border-bottom: 1px solid $black10;
        }
    }

    &:first-of-type {

        .workflow-module-panel {
            border-top: 1px solid $black10;
        }
    }

    &:last-of-type {

        .workflow-module-panel {
            border-bottom: 1px solid $black10;
        }
    }

    .workflow-group-panel {
        border-top-left-radius: 0 !important;
        border-top-right-radius: 0 !important;
    }

    &:not(:first-of-type) {

        .workflow-module-panel {
            border-top-left-radius: 0 !important;
            border-top-right-radius: 0 !important;
        }
    }

    &:not(:last-of-type) {

        .workflow-module-panel,
        .workflow-group-panel {
            border-bottom-left-radius: 0 !important;
            border-bottom-right-radius: 0 !important;
        }

        .workflow-module-bottom-shadow {
            height: 20px;
            background: linear-gradient(180deg, rgba(255, 255, 255, 0) 40.1%, rgba(216, 216, 216, 0.444) 100%);
        }
    }

    &:last-of-type {

        .workflow-group-panel {

            &:not(:last-of-type) {
                border-bottom-left-radius: 0 !important;
                border-bottom-right-radius: 0 !important;
            }
        }
    }

    .mat-expansion-panel-header {

        &.cdk-focused {
            background-color: inherit !important;
        }
    }

    .workflow-module-panel {

        .mat-expansion-panel-body {
            padding: 0;
        }

        &.mat-expanded {

            .workflow-module-header {
                position: relative;
                // NOTE: using material's opacity to match other shadows on the accordion
                /* box-shadow: 0px 3px 3px -3px rgba(0, 0, 0, 0.12),
                    0px 2px 2px 0px rgba(0, 0, 0, 0.14),
                    0px 1px 5px 0px rgba(0, 0, 0, 0.12); */
            }
        }

        .mat-expansion-panel-header {
            padding: 0 60px;
            border-bottom-left-radius: 0 !important;
            border-bottom-right-radius: 0 !important;

            @include xs {
                padding: 0 20px;
            }

            &:not([aria-disabled="true"]) {

                &:hover {
                    background: none !important;
                }
            }
        }

        .form-wrapper {
            display:flex;
            flex-direction: row;
            justify-content: flex-start;

            @include ltesm {
                flex-direction: column;
                text-align: center;
                li {
                    text-align: left;
                }
            }

            .image-wrapper {
                height: 200px;
                display: flex;
                flex-direction: row;
                justify-content: center;
                width: auto;

                @include gtemd {
                    justify-content: flex-start;
                    margin-right: 37px;
                    width: 163px;
                }
            }

            .form-field {
                flex: 1;
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
            }

            .workflow-form-header {
                display: flex;
                justify-content: center;

                @include ltesm {
                    flex-direction: column;
                    align-items: center;
                }
            }

            .vertical-radio-options {
                mat-radio-button {

                    &:first-of-type {
                        margin-bottom: 25px;
                    }
                }
            }

            .horizontal-radio-options {
                flex-direction: row;

                @include ltesm {
                    justify-content: center;
                }

                mat-radio-button {
                    &:first-of-type {
                        margin-right: 50px;
                    }
                }
            }

            &.narrow-md {

                @include md {
                    flex-direction: column !important;
                }
            }
        }
    }
}

app-workflow-module,
.create-business-form {

    .header-container {
        flex: 1;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        margin-right: 15px;

        img {
            display: none;

            @include gtesm {
                display: block;
                height: 100px;
                margin-right: 24px;
                width: 100px;
            }
        }

        .group-complete {
            display: flex;
            flex-direction: column;
            justify-content: center;
            margin: 0 10px;
        }

        .action-container {
            flex: 1;
            display: flex;
            justify-content: flex-end;
        }
    }
}

