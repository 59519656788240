@import 'liquid';
@import 'mixins';
@import 'palette';

.liquid-light-theme {

    .mat-button-wrapper {
        display: flex;
        flex-direction: row;
        text-decoration: none;
        align-items: center;
        justify-content: center;
        text-align: center;

        i-feather:not(.nav-icon-icon) {
            margin: -1px 3px 3px -3px;
            height: 20px;
            width: 20px;
        }
    }

    .mat-button.cdk-focused {
        .mat-button-focus-overlay{

        opacity: 0;
        }
    }

    .mat-button-focus-overlay {
        background-color: $primary100;
    }

    .mat-button:not([disabled]):hover .mat-button-focus-overlay {
        opacity: 0.06;
    }

    .mat-button-base {
        display: flex;
        align-items: center;
        height: 48px;
        border-radius: 8px;
        color: $primary100;
        flex-shrink: 0;
        font-weight: 600;

        &:disabled {
            i-feather {
                color: $black40;
            }
        }
    }

    // Button without borders
    .mat-button.no-borders{
        height: auto;
        width: auto;
        padding: 0;

        :hover {
            text-decoration: underline;
        }

        .mat-button-focus-overlay{
            background-color: transparent;
        }

        .mat-ripple {
            display: none;
        }

        &:disabled {
            background-color: transparent;
            :hover {
                text-decoration: none;
            }
        }
    }

    .mat-flat-button {
        display: flex;
        min-width: 100px;
        justify-content: center;
        align-items: center;
    }

    .mat-stroked-button {
        border: 1px solid $primary100;
        background-color: $white-default;
        display: flex;
        min-width: 100px;
        justify-content: center;
        align-items: center;

        &:disabled {
            border-color: $black20;

            .mat-button-wrapper {
                color: $black20;
            }
        }

        &:not(:disabled).mat-warn {
            border-color: $error-default;
        }

        &.image-upload-button {
            display: flex;
            justify-content: center;
            border-style: dashed;
            background-color: $black5;

            .upload-button-content {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;

                i-feather {
                    color: $black60;
                    margin-bottom: 4px;
                    @include large-icon-size;
                }
            }
        }
    }

    .mat-icon-button {
        height: 40px;
        width: 40px;
        justify-content: center;
        align-items: center;

        .mat-button-focus-overlay {
            background-color: transparent;
        }

        .mat-button-wrapper {
            i-feather {
                height: 20px;
                width: 20px;
                margin: 0px 0px 0px 0px;
            }
        }

        .mat-ripple {
            display: none;
        }
    }

    .mat-mini-fab,
    .mat-fab {
        height: 20px;
        width: 20px;
        justify-content: center;
        border-radius: 50%;
        i-feather:not(.nav-icon-icon) {
            margin: 0;
            width: 16px;
            height: 16px;
        }
        .mat-button-wrapper {
            padding: 0;
        }
    }
}
