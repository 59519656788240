@import 'mixins';

.mat-chip {
    color: $primary100 !important;
    background-color: $primary10 !important;
    border: 1px solid $primary30 !important;
    letter-spacing: 0.2px;
    min-width: 0;

    &:not(.chip-status-badge):not(.site-status-badge) {
        height: 34px !important;

        .chip-value {
            max-width: 200px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }
    }

    &.site-status-badge {
        height: 34px !important;

        .chip-value {
            white-space: nowrap;
        }
    }

    &.chip-status-badge {
        @extend .small1;
        font-weight: normal;
        flex-shrink: 0;
        white-space: normal;
        text-transform: uppercase;
        text-align: center;
        border: none !important;
        max-width: 110px;
        height: auto;
    }

    &.chip-warn {
        color: $warning-dark !important;
        background-color: $warning-light !important;
        border-color: $warning-dark !important;
    }

    &.chip-error {
        color: $error-dark !important;
        background-color: $error-light !important;
        border-color: $error-dark !important;
    }

    &.chip-success {
        color: $success-dark !important;
        background-color: $success-light !important;
        border-color: $success-dark !important;
    }

    &.clickable {
        cursor: pointer;
    }

    &:hover {

        &:not(.clickable) {
            background-color: $primary10 !important;
            border: 1px solid $primary30 !important;

            &.chip-status-badge {
                border: none !important;
            }

            &.chip-warn {
                color: $warning-dark !important;
                background-color: $warning-light !important;
                border-color: $warning-dark !important;
            }

            &.chip-error {
                color: $error-dark !important;
                background-color: $error-light !important;
                border-color: $error-dark !important;
            }

            &.chip-success {
                color: $success-dark !important;
                background-color: $success-light !important;
                border-color: $success-dark !important;
            }
        }

        &.clickable {
            background-color: $primary20 !important;
            border-color: $primary60 !important;

            &.chip-warn {
                background-color: $warning-default !important;
                border-color: $warning-dark !important;
            }

            &.chip-error {
                background-color: $error-default !important;
                border-color: $error-dark !important;
                color: $white-default !important;
            }

            &.chip-success {
                background-color: $success-default !important;
                border-color: $success-dark !important;
                color: $white-default !important;
            }
        }

        &::after {
            opacity: 1;
        }
    }

    &:focus {

        &.clickable {
            color: $primary100 !important;
            background-color: $primary10 !important;
            border-color: $primary30 !important;

            &.chip-warn {
                color: $warning-dark !important;
                background-color: $warning-light !important;
                border-color: $warning-dark !important;
            }

            &.chip-error {
                color: $error-dark !important;
                background-color: $error-light !important;
                border-color: $error-dark !important;
            }

            &.chip-success {
                color: $success-dark !important;
                background-color: $success-light !important;
                border-color: $success-dark !important;
            }
        }
    }

    &:active {

        &:not(.clickable) {
            box-shadow: none !important;
        }
    }

    &.focused {

        &.clickable {
            background-color: $primary70 !important;
            border-color: $primary100 !important;
            color: $white-default !important;

            &.chip-warn {
                background-color: $warning-dark !important;
                border-color: $warning-dark !important;
            }

            &.chip-error {
                background-color: $error-dark !important;
                border-color: $error-dark !important;
            }

            &.chip-success {
                background-color: $success-dark !important;
                border-color: $success-dark !important;
            }
        }
        &.chip-status-badge {
            border: none !important;
        }
    }

    &::after {
        background: $primary20 !important;
    }

    .chip-img-prefix {
        @include large-icon-size;
        border-radius: 50%;

        &:nth-of-type(1) {
            margin-right: 10px;
        }
    }

    .mat-chip-trailing-icon,
    .chip-icon-prefix {
        height: 20px !important;
        width: 20px !important;
    }

    .chip-icon-prefix {
        margin-right: 10px;
    }

    .mat-chip-trailing-icon {
        opacity: 1 !important;
        color: inherit !important;
        margin-left: 10px !important;
    }

    .chip-icon-suffix {
        @extend .mat-chip-trailing-icon;
        cursor: default;
    }
}

.narrow-chip-list {

    .chip-value {
        max-width: 110px !important;
    }
}

.mat-chip-list-wrapper {
    margin: 0 !important;
}

.liquid-light-theme {
    .mat-chip.mat-standard-chip {
        &.mini-chip:not(.chip-status-badge) {
            font-size: 10px !important;
            font-weight: normal !important;
            line-height: 14px !important;
            padding: 0px 4px !important;
            border-radius: 8px;
            min-height: auto !important;
            max-height: 16px !important;
            vertical-align: middle !important;
            @include ellipsis-overflow;

            &.removable {
                i-feather.mat-chip-remove.mat-chip-trailing-icon {
                    height: 10px !important;
                    width: 10px !important;
                    margin: 0 !important;
                    padding: 0 !important;
                    margin-left: 2px !important;
                }
            }
            span {
                @include ellipsis-overflow;
            }
            &.collapsible {
                // accounts for padding + border
                max-width: 115px;
                span {
                    max-width: 105px;
                }
            }

        }
    }
}
