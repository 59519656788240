@import "palette";

.liquid-light-theme {
    .mat-table {

        .mat-cell,
        .mat-header-cell {
            &.mat-column-select {
                flex: 0 0 20px;
                padding: 0 15px 0 15px;
            }

            &.mat-column-invitedDate {
                flex: 0 0 150px;
                padding: 0 60px 0 15px;
            }
        }
    }

    table:not(.no-hover-color) {
        tr {
            &:hover {
                td {
                    background-color: $primary10;
                }
            }
        }
    }

    .data-table {
        min-width: fit-content;

        @include xs {
            min-width: auto;
        }

        .table-container {
            table.mat-table {
                overflow-x: auto;

                table-layout: fixed;
                min-width: 100%;
                border-top-left-radius: 8px;
                border-top-right-radius: 8px;

                thead,
                .mat-header-row {
                    border-top-left-radius: 8px;
                    border-top-right-radius: 8px;
                    background-color: $black10;
                }

                th {
                    font-size: 14px;

                    .mat-sort-header-container {
                        button.mat-sort-header-button {
                            color: $black100 !important;
                            font-weight: 600 !important;
                            text-transform: none !important;
                        }
                    }

                    &.right-aligned {
                        .mat-sort-header-container {
                            justify-content: flex-end;

                            .mat-sort-header-arrow {
                                margin-right: -18px;
                            }
                        }
                    }

                    &:first-of-type {
                        border-top-left-radius: 8px;
                    }

                    &:last-of-type {
                        border-top-right-radius: 8px;
                        padding-right: 32px;
                    }
                }

                th,
                td {
                    padding: 0 16px;
                    border-color: $black10;

                    &:first-of-type {
                        padding-left: 32px;
                        border-left: 1px solid $black10;
                    }

                    &:last-of-type {
                        padding-right: 32px;
                        border-right: 1px solid $black10;
                    }
                }

                .mat-header-row.stuck {
                    th.mat-header-cell {
                        border-radius: 0 !important;
                        top: -1px !important;
                    }
                }
            }
        }

        .lqd-paginator:not(.no-sticky) {
            .mat-paginator-outer-container {
                position: relative;

                .mat-paginator-container {
                    max-width: 500px;
                    position: sticky;
                    position: -webkit-sticky;
                    left: 0;
                    padding: 0;
                    padding-left: 28px;
                    justify-content: flex-start !important;
                }
            }
        }
    }
}
