@import "palette";

.liquid-light-theme {

    mat-hint {
        display: flex;
        align-items: center;

        i-feather {
            height: 15px;
            width: 15px;
            margin: 0 3px;
        }
    }

    .mat-form-field {
        font-size: 16px;

        i-feather {
            color: $black40;
        }

        .mat-form-field-prefix {
            bottom: -7px;
            padding-right: 10px;
            span {
                position: relative;
                bottom: 7px;
            }
        }

        .mat-form-field-suffix {
            bottom: -7px;
            padding-left: 10px;
            span {
                position: relative;
                bottom: 7px;
            }
        }

        .mat-form-field-ripple {
            background-color: $primary100;
        }

        &.auto-width-form-field {

            .mat-form-field-infix {
                width: auto;
            }
        }
    }

    .mat-form-field.mat-focused {

        i-feather {
            color: $black60;
        }

        .mat-hint {
            font-size: 12px;
            color: $primary100;
        }
    }

    .mat-form-field.mat-form-field-disabled {
        color: rgba(0, 0, 0, 0.38)
        i-feather {
            color: $black10;
        }
    }

    .mat-form-field-disabled .mat-form-field-underline {
        background-image: linear-gradient( to right, rgba(0, 0, 0, 1) 0, rgba(0, 0, 0, 0.42) 33%, #c2c7cc 0 ) !important;
        background-size: 1px 100% !important;
        background-repeat: repeat-x !important;
    }

    .mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label {
        transform: translateY(-1.2em) scale(0.75);
    }

    .mat-form-field-appearance-fill {
        .mat-form-field-prefix {
            bottom: -7px;
        }

        .mat-form-field-suffix {
            bottom: 0;
        }

        .mat-form-field-label {
            top: 25px;
        }
    }

    .mat-form-field-appearance-fill.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label {
        transform: translateY(-1.2em) scale(0.75);
    }

    .mat-radio-group {
        display: flex;
        flex-direction: column;

        &.horizontal {
            flex-direction: row;

            mat-radio-button {
                margin-right: 5px;

                &:not(:last-of-type) {
                    margin-right: 10px;
                }
            }
        }

        &.radio-wrap {

            .mat-radio-label {
                align-items: flex-start;
                text-align: left;
                .mat-radio-label-content {
                    white-space: normal;
                }
            }
        }

        .mat-radio-button {
            margin: 5px 0;
        }

        .mat-radio-outer-circle {
            border-color: $primary100;
        }
    }

    .mat-checkbox-frame {
        border-color: $primary100;
    }

    .mat-checkbox {

        &.wrap-label {

            .mat-checkbox-label {
                white-space: normal;
                line-height: normal;
            }
        }
    }


}
