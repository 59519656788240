@import 'palette';
@import 'breakpoints';
@import 'buttons';


#frmDiv {
    height: 100%;
    background-color: $white-default;

    form {
        height: 100%;
        display: flex;
        justify-content: center;
    }

    table {
        display: table;
        height: 100%;
    }

    tbody {
        width: 100%;

        @include xs {
            height: 100%;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: space-evenly;
        }
    }

    label {
        padding-right: 10px;
    }

    input {
        height: 25px;
        font-size: 14px;
        border: none;
        border-bottom: thin solid $primary60;
        background-color: transparent;

        &:focus, &:hover {
            outline: none;
            border-bottom: thin solid $primary100;
        }
    }

    select {
        height: 25px;
        font-size: 14px;
        outline: 0;
        box-shadow: none;
        background-image: none;
        cursor: pointer;

        border: none;
        border-bottom: thin solid $primary60;
        background-color: transparent;

        &:focus, &:hover {
            outline: none;
            border-bottom: thin solid $primary100;
        }
    }

    .completeButton {
        height: 48px;
        width: 100px;
        font-weight: 600;
        border-radius: 8px;
        background-color: $primary100;
        color: $white-default;
        border: 0;
        box-shadow: none;
        outline: none;
        margin-top: 10px;
    }
    .cancelButton {
        height: 48px;
        width: 100px;
        font-weight: 600;
        border-radius: 8px;
        background-color: $white-default;
        color: $primary100;
        border: thin solid $black20;
        box-shadow: none;
        outline: none;
        margin-top: 10px;
    }

    .creZipField {
        width: 50%;
    }

    .creAddressTwoLabel {
        label {
            &::after {
                content: "Billing Address 2";

                @include xs {
                    float: left;
                }
            }
        }
    }

    .creNameRow,
    .creAddressOneRow,
    .creAddressTwoRow,
    .creCityRow,
    .creStateZipRow,
    .creNumberRow,
    .creCVV2Row,
    .creTypeRow,
    .creExpirationRow {
        padding-top: 5px;
        padding-bottom: 5px;

        @include xs {
            display: flex;
            flex-direction: column;
            width: 100%;
        }

        td {
            font-size: 16px;
            &:first-of-type {
                text-align: right;

                @include xs {
                    text-align: left;
                }
            }
        }
    }

    .creNameField,
    .creAddressOneField,
    .creAddressTwoField,
    .creAddressField,
    .creNumberField,
    .creCVV2Field {
        width: 75%;
    }

    .creStateZipRow {
        tr {
            @include xs {
                display: flex;

                td {
                    flex: 0.5
                }
                td:first-of-type {
                    select {
                        width: 100%;
                    }
                }
            }
        }
    }

    .creButtonRow {
        @include xs {
            width: 100%;
            display: flex;
            justify-content: center;
            margin-top: 20px;
        }
        .creButtonLabel {
            text-align: end;

            .cancelButton {
                margin-left: 10px;
            }
        }
    }
}
