@import "breakpoints";
@import "palette";

$filter-sort-width: 308px;
$avatar-size: 100px;
$avatar-placeholder-size: 120px;
$big-avatar-placeholder-size: 125px;
$big-avatar-size: 150px;
$card-width: 308px;
$mobile-card-width: 290px;
$card-height: 120px;
$card-spacing: 27px;
$card-spacing-bottom: 23px;

* {
    box-sizing: border-box;
}

html,
body {
    background-color: $white-dark;
    color: $primary100;
    font-family: "Poppins", sans-serif;
    font-size: 14px;
    font-weight: 300;
    height: 100%;
    letter-spacing: 0px;
    margin: 0;
    min-height: 100vh;
    padding: 0;
    scrollbar-width: thin;
    scrollbar-color: $black60 transparent;
    overflow-x: hidden;
}

*::-webkit-scrollbar {
    width: 8px;
    height: 8px;
}

*::-webkit-scrollbar-track {
    background: transparent;
}

*::-webkit-scrollbar-thumb {
    background-color: $black60;
    border-radius: 3px;
    border: none;
}

app-component {
    display: flex;
    min-height: 100vh;
    width: 100%;
}

app-liquid-app,
app-root-component,
app-root-component main {
    flex: 1;
    display: flex;
}

.router-outlet.main-app-router {
    flex: 1;
    display: flex;
}

h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4,
h5,
.h5,
h6,
.h6 {
    margin: 8px 0 16px 0;
}

h6,
.h6 {
    text-transform: uppercase;
}

h1,
.h1 {
    font-size: 32px;
    line-height: 40px;
    color: $primary100;
    font-weight: 300;
    letter-spacing: 0.2px;
}

h2,
.h2 {
    color: $primary100;
    font-size: 20px;
    font-weight: 600;
    letter-spacing: 0px;
    line-height: 26px;

    @include gtesm {
        font-size: 26px;
        line-height: 32px;
    }
}

h3,
.h3 {
    font-size: 32px;
    line-height: 40px;
    color: $primary100;
    font-weight: 600;
    letter-spacing: 0.1px;
}

h4,
.h4 {
    font-size: 26px;
    line-height: 32px;
    color: $primary100;
    font-weight: 600;
    letter-spacing: 0.2px;
}

h5,
.h5 {
    font-size: 20px;
    line-height: 26px;
    color: $primary100;
    font-weight: 600;
    letter-spacing: 0.2px;
}

h6,
.h6 {
    font-size: 18px;
    line-height: 24px;
    color: $primary100;
    font-weight: 600;
    letter-spacing: 0.2px;
}

.text-h6 {
    color: $black100;
    font-size: 18px;
    font-weight: 600;
    letter-spacing: 0.2px;
    line-height: 24px;
}

.subtitle1 {
    font-size: 18px;
    line-height: 24px;
    color: $black100;
    letter-spacing: 0.1px;
    font-weight: bold;
    text-transform: initial;
}

.subtitle2 {
    font-size: 16px;
    line-height: 24px;
    color: $black100;
    letter-spacing: 0.1px;
    font-weight: 600;
}

.subtitle3 {
    font-size: 14px;
    line-height: 18px;
    color: $black100;
    letter-spacing: 0.1px;
    font-weight: 600;
}

p,
.body1 {
    font-size: 16px;
    line-height: 24px;
    font-weight: normal;
    letter-spacing: 0.1px;
    color: $black100;
}

.body2 {
    font-size: 14px;
    line-height: 22px;
    font-weight: normal;
    letter-spacing: 0.1px;
    color: $black100;
}

.body3 {
    font-size: 14px;
    line-height: 18px;
    font-weight: normal;
    color: $black100;
    letter-spacing: 0.1px;
}

.small1 {
    font-size: 12px;
    line-height: 16px;
    font-weight: 600;
    letter-spacing: 0.2px;
    color: $black100;
}

.small2,
.small2 a {
    font-size: 12px !important;
    line-height: 16px;
    font-weight: normal;
    letter-spacing: 0.2px;
}

.small2 {
    color: $black100;
}

.small3 {
    @extend .small2;
    font-size: 10px !important;
}

.button1 {
    font-weight: 400;
    font-size: 16px;
    line-height: 18px;
    letter-spacing: 0.2px;
    color: $black100;
}

.button2 {
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: 0.2px;
    color: $black100;
}

.initials1 {
    font-weight: 600;
    font-size: 13px;
    line-height: 16px;
    letter-spacing: 0.2px;
    color: $black100;
}

.label1 {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.2px;
    color: $black100;
}

.label2 {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.2px;
    color: $black100;
}

.label3 {
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.2px;
    color: $black100;
}

.big1 {
    font-weight: 600;
    font-size: 56px;
    line-height: 32px;
    letter-spacing: 0.2px;
    color: $primary100;
}

.big2 {
    font-weight: bold;
    font-size: 50px;
    line-height: 32px;
    letter-spacing: 0.2px;
    color: $primary100;
}

.big3 {
    font-weight: bold;
    font-size: 22px !important;
    line-height: 24px;
    letter-spacing: 0.2px;
    color: $primary100;
}

.low-contrast {
    color: $black60 !important;
}

hr {
    border: 1px solid $black20;
}

.subheader2 {
    font-size: 16px;
    color: $black100;
    margin-bottom: 10px;
}

.note {
    font-size: 12px;
    color: $primary40;
    margin-bottom: 2px;
    margin-top: -15px;
    z-index: 1000 !important;
    position: relative;
}

.form-field-placeholder {
    padding-bottom: 1.25em;
    height: 1.125em;
}

.emph {
    font-weight: bold;
    font-style: italic;
    color: $error-light;
}

caption {
    font-size: 12px;
    font-weight: 400;
    letter-spacing: 0.4px;
}

.overline {
    font-size: 12px;
    font-weight: 400;
    letter-spacing: 2px;
    text-transform: uppercase;
}

.full-width {
    min-width: 100%;
}

.avatar {
    background-color: $white-default;
}

div {
    &.logo-black {
        display: block;
        background: url("/assets/img/logo/liquid_logo.svg") no-repeat 0 0;
        background-size: 80px 36px;
        width: 80px;
        height: 36px;
    }

    &.logo-small {
        display: block;
        background: url("/assets/img/logo/Liquid_Graphic_Logo.svg") no-repeat 0 0;
        background-size: 29px 19px;
        width: 29px;
        height: 36px;
    }

    &.logo-white {
        display: block;
        background: url("/assets/img/logo/Liquid_Graphic_Logo.svg") no-repeat 0 0;
        background-size: 80px 36px;
        min-width: 80px;
        min-height: 36px;
    }
}

.round {
    border-radius: 50%;
    padding: 4px;
}

.oval {
    border-radius: 20px;
    margin-left: 14px;
    padding: 0px 10px;
    font-size: 14px;
}

.removed,
.deleted {
    color: $primary40;

    &.round,
    &.oval {
        background-color: rgba($color: $primary40, $alpha: 0.08);
    }
}

.disabled {
    color: $black60;

    &.round,
    &.oval {
        background-color: $white-default !important;
    }
}

.yellow {
    color: $warning-default !important;

    &.round,
    &.oval {
        background-color: $black5 !important;
    }
}

.dark-green {
    color: $success-dark !important;
}

i-feather.site-icon {
    font-size: 0;
    width: 30px;
    height: 30px;
    padding: 5px;
    margin-right: 10px;

    &.big {
        width: 72px;
        height: 72px;
    }
}

a {
    cursor: pointer;
    text-decoration: underline;
    font-weight: normal;
    font-size: 14px;
    color: $primary100;

    &.mat-button {
        text-decoration: none;
    }
}

.module-outlet {
    >.ng-star-inserted {
        flex: 1;
    }
}

.module-container {
    flex: 1 1 100%;
    display: flex !important;
    flex-direction: column;
    justify-content: flex-start;

    .module-contents {
        flex: 1 1 100%;
        padding-top: 0;
        display: flex;
        flex-direction: column;

        .subnav-content-container {
            flex: 1 1 100%;
            display: flex;
            padding: 0 0 0 16px;
            margin-bottom: 0;

            .subnav-content {
                min-height: min-content;
                display: flex;
                flex: 1 1 100%;
                width: 100%;

                >.ng-star-inserted {
                    flex: 1;
                    display: flex;
                    flex-direction: column;
                    justify-content: stretch;
                    width: 100%;
                }
            }
        }
    }

    .subnav-mobile {
        background-color: $white-default;
        border-radius: 8px;
        box-shadow: 0px 5px 8px rgba(151, 181, 233, 0.15);
        margin-bottom: 16px;

        @include gtesm {
            display: none;
        }

        @include xs {
            margin-right: 0 !important;
        }
    }

    .subnav {
        display: none;

        .mat-tab-link {
            min-width: initial;
            padding: 0 12px;
        }

        &-wrap {
            .mat-tab-header-pagination {
                display: none !important;
            }

            .mat-tab-links {
                flex-flow: wrap;
            }
        }

        @include gtesm {
            display: flex;
        }

        &.no-bottom-border {
            border-bottom: 0;
        }
    }
}

.overflow-x-scroll {
    overflow-x: scroll;
}

.page-container {
    opacity: 1;
    // transition: .25s;
    position: absolute;
    top: 97px;
    bottom: 0px;
    left: 0;
    right: 0;
    overflow: auto;

    .page-header-outer {
        display: flex;
        flex-flow: row;
        justify-content: flex-start;
        align-items: center;
        align-content: center;
        padding: 10px 24px 10px 24px;
        margin-bottom: 16px;

        .page-header {
            flex: 1 1 auto;

            .header-text {
                color: $primary100;
                font-size: 32px;
                font-weight: 600;
                line-height: 42px;
                text-align: left;
                vertical-align: middle;

                .mat-input-element {
                    font-size: 32px;
                    line-height: 32px;
                    text-align: left;
                    vertical-align: middle;
                }

                .subtext {
                    font-size: 14px;
                }
            }

            .instruction {
                padding-top: 10px;
                font-size: 22px;
            }
        }

        .page-header-actions {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            justify-content: center;

            button {
                margin: 8px;
            }
        }
    }

    .page-contents {
        padding: 0px 24px;
        color: $primary100 !important;

        .contents-list {
            display: flex;
            flex-flow: row wrap;
            align-items: flex-start;
            align-content: flex-start;
        }
    }

    .page-footer {
        display: flex;
        flex-flow: row wrap;
        justify-content: flex-start;
        align-items: flex-start;
        align-content: flex-start;
        padding: 0px 24px;
        margin-top: 16px;

        .footer-left {
            flex: 0 1 auto;
        }

        .footer-actions {
            flex: 1 1 auto;
            display: flex;
            flex-flow: row wrap;
            justify-content: space-between;
            align-items: flex-start;
            align-content: flex-start;
        }
    }

    .page-actions {
        flex: 0 0 auto;
    }
}

.item-editor {
    .actions {
        display: flex;
        justify-content: flex-end;
        margin: 20px 0px 22px 0px;
    }
}

.container-with-sidenav {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;

    //width: 100%;
    //height: 474px;
    //max-height: 474px;
    .sidenav {
        flex-grow: 0;
        margin: 0px 24px 0px 0px;
        overflow: auto;
        width: 260px;
        max-width: 260px;

        .list-container {
            display: table;
            width: 100%;

            .list-row {
                display: table-row;

                .list-row-item {
                    line-height: 22px;
                    border-radius: 6px;
                    font-size: 14px;
                    display: table-cell;
                    vertical-align: middle;
                    padding: 12px 12px;
                }

                &:not(:hover) {
                    color: $black60;
                }

                &:hover {
                    cursor: pointer;

                    .list-row-item {
                        font-weight: bold;
                    }
                }
            }

            .selected {
                cursor: pointer;

                .list-row-item {
                    font-weight: bold;
                }
            }
        }
    }

    .contents {
        flex-grow: 1;

        .mat-checkbox,
        .mat-radio-button {
            font-size: 14px;
        }
    }
}

.page-card-contents {
    box-shadow: 2px 0px 14px $black10;
    background-color: $white-default;
    padding: 30px;
    color: $primary100;
}

.pull-left {
    display: flex;
    justify-content: flex-start;
}

.pull-right {
    display: flex;
    justify-content: flex-end;
}

.flex-row {
    display: flex !important;
    flex-direction: row;
}

.center-aligned-flex-row {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.mod-flex-row {
    display: flex !important;
    flex-direction: row !important;
}

.detail-box {
    display: flex;
    align-content: space-between;
    padding: 1em 0;
}

.action-box {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.w .w-10 {
    width: 10% !important;
}

.w-15 {
    width: 15% !important;
}

.w-19 {
    width: 19% !important;
}

.w-20 {
    width: 20% !important;
}

.w-25 {
    width: 25% !important;
}

.w-30 {
    width: 30% !important;
}

.w-33 {
    width: 33% !important;
}

.w-37 {
    width: 37% !important;
}

.w-40 {
    width: 40% !important;
}

.w-45 {
    width: 45% !important;
}

.w-47 {
    width: 47% !important;
}

.w-49 {
    width: 49% !important;
}

.w-50 {
    width: 50% !important;
}

.w-55 {
    width: 55% !important;
}

.w-52 {
    width: 52% !important;
}

.w-59 {
    width: 59% !important;
}

.w-60 {
    width: 60% !important;
}

.w-63 {
    width: 63% !important;
}

.w-67 {
    width: 67% !important;
}

.w-70 {
    width: 70% !important;
}

.w-75 {
    width: 75% !important;
}

.w-79 {
    width: 79% !important;
}

.w-80 {
    width: 80% !important;
}

.w-98 {
    width: 98% !important;
}

.w-100 {
    width: 100% !important;
}

.no-margin {
    margin: 0;
}

.mr-1 {
    margin-right: 1% !important;
}

.mr-2 {
    margin-right: 2% !important;
}

.mr-5 {
    margin-right: 5% !important;
}

.mr-6 {
    margin-right: 6% !important;
}

.mr-666 {
    margin-right: 6.66% !important;
}

.mrv-5 {
    margin-right: 5px !important;
}

.mrv-10 {
    margin-right: 10px !important;
}

.mrv-16 {
    margin-right: 16px !important;
}

.mrv-24 {
    margin-right: 24px !important;
}

.mrv-30 {
    margin-right: 24px !important;
}

.mrv-48 {
    margin-right: 24px !important;
}

.mtv-10 {
    margin-top: 10px;
}

.mtv-16 {
    margin-top: 16px;
}

.mtv-20 {
    margin-top: 20px;
}

.mtv-40 {
    margin-top: 40px;
}

.mbv-5 {
    margin-bottom: 5px;
}

.mbv-10 {
    margin-bottom: 10px;
}

.mbv-16 {
    margin-bottom: 16px;
}

.mbv-20 {
    margin-bottom: 20px;
}

.mbv-24 {
    margin-bottom: 24px;
}

.mbv-30 {
    margin-bottom: 30px;
}

.pbv-10 {
    padding-bottom: 10px;
}

.mlv-5 {
    margin-left: 5px;
}

.mlv-10 {
    margin-left: 16px;
}

.mlv-16 {
    margin-left: 16px;
}

.mlv-24 {
    margin-left: 24px;
}

.pbv-10 {
    padding-bottom: 10px;
}

.pbv-20 {
    padding-bottom: 20px;
}

.ptv-10 {
    padding-top: 10px;
}

.ptv-20 {
    padding-top: 10px;
}

.ptv-30 {
    padding-top: 30px;
}

.pointer {
    cursor: pointer !important;
}

.page-sub-header-container {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    align-items: center;
    align-content: center;
    padding: 0px 51px 0px 24px;
    margin: 0px 0px 25px 0px;
}

.filter-sort {
    padding: 0px;
    margin: 0px;
    list-style: none;
    display: flex;
    flex-flow: wrap;
    justify-content: flex-start;

    .filter,
    .sort {
        min-width: $filter-sort-width;
        max-width: $filter-sort-width;
        width: $filter-sort-width;
        font-size: 14px;
        margin-right: $card-spacing;
    }
}

.list-item-container {
    width: 100%;
}

.list-item {
    box-shadow: 2px 0px 14px $black10;

    &:hover {
        cursor: pointer;
        box-shadow: 2px 4px 20px $black40;
    }
}

.list-item.view-mode,
.list-item.edit-mode {
    width: calc(100% - 50px);
    margin-bottom: 16px;
    padding: 0px 25px;
    display: flex;

    &.view-mode {
        flex-direction: column;
        justify-content: flex-start;
        align-items: stretch;
        align-content: center;

        .item-name-container {
            width: 100%;
            display: flex;
            flex-direction: row;
            justify-content: flex-end;
            align-items: center;
            align-content: center;
            padding: 17px 0px;
            font-size: 22px;

            .item-name {
                flex-grow: 1;
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: center;
                align-content: center;
            }
        }
    }

    &.edit-mode {
        flex-direction: column;
        justify-content: flex-start;
        align-items: stretch;
        align-content: center;

        .item-name-container {
            width: 100%;
            display: flex;
            flex-direction: row;
            justify-content: flex-end;
            align-items: center;
            align-content: center;
            font-size: 22px;
            padding: 17px 0px;

            .item-name {
                flex-grow: 1;
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: center;
                align-content: center;
            }
        }

        .item-editor {
            width: 100%;
        }
    }

    .item-actions.i-feather {
        margin-right: 16px;
    }
}

.item-name-status {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    align-content: center;
}

b {
    font-weight: bold;
}

.pre-format {
    white-space: pre-wrap;
}

.table-container {
    th {
        button {
            text-transform: uppercase;
        }
    }
}

// Notifications
.alert {
    border-radius: 8px;
    border-style: solid;
    border-width: 1px;
    margin: 1em 0;
    padding: 0 17px;
    position: relative;

    .close {
        position: absolute;
        top: 0;
        right: 0;
        color: $black60;
    }

    p {
        @extend .subtitle3;
        color: inherit;
    }
}

.alert-error {
    background-color: rgba($color: $error-default, $alpha: 0.04);
    border-color: $error-default;
    color: $error-dark;
}

// Notifications
.alert {
    border-radius: 8px;
    border-style: solid;
    border-width: 1px;
    margin: 1em 0;
    padding: 0 17px;
    position: relative;

    .close {
        position: absolute;
        top: 0;
        right: 0;
        color: $black60;
    }

    p {
        @extend .subtitle3;
        color: inherit;
    }
}

.alert-error {
    background-color: rgba($color: $error-default, $alpha: 0.04);
    border-color: $error-default;
    color: $error-dark;
}

.no-list-type {
    list-style-type: none;
}

.checkbox-top-alignment {
    .mat-checkbox-inner-container {
        margin-top: 4px;
    }
}

/* STATUS BADGES */
.status-badge {
    @extend .small1;

    text-align: center;
    display: inline-block;
    padding: 7px 12px;
    border-radius: 8px;
    text-transform: uppercase;
    color: $primary100;
    background-color: $primary10;

    &.clickable {
        cursor: pointer;
    }

    &.orange,
    &.warn {
        color: $warning-dark;
        @include bg-opacity($warning-dark);
    }

    &.green,
    &.success {
        color: $success-dark;
        @include bg-opacity($success-dark);
    }

    &.red,
    &.error {
        color: $error-dark;
        @include bg-opacity($error-dark);
    }
}

/* NUMBER BADGE */
.number-badge-container {
    display: flex;
    width: 40px;

    .number-badge[number-badge] {
        &:after {
            content: attr(number-badge);
            height: 20px;
            min-width: 20px;
            text-align: center;
            border-radius: 50%;
            border: solid 3px $primary20;
            float: left;
        }
    }
}

/* XS VIEWPORT */
.xs-only {
    display: none !important;

    @include xs {
        display: block !important;
    }
}

.xs-hide {
    display: block;

    @include xs {
        display: none !important;
    }
}

.xs-bottom-divider {
    @include xs {
        border-bottom: 1px solid $black20;
    }
}

.xs-top-divider {
    @include xs {
        border-top: 1px solid $black20;
    }
}

.gtelg-only {
    display: none;

    @include gtelg {
        display: block;
    }
}

.gtemd-only {
    display: none;

    @include gtemd {
        display: block;
    }
}

.ltemd-only {
    display: none;

    @include ltemd {
        display: block;
    }
}

.ltesm-only {
    display: none;

    @include ltesm {
        display: block;
    }
}

.md-only {
    display: none;
}

.md-not {
    display: block;
}

@include md {
    .md-only {
        display: block;
    }

    .md-not {
        display: none;
    }
}

.center-aligned {
    text-align: center;
}

.right-aligned {
    text-align: right;
}

.left-aligned {
    text-align: left;
}

.hidden {
    display: none !important;
}

.bottom-divider {
    border-bottom: 1px solid $black20;
}

.top-divider {
    border-top: 1px solid $black20;
}

.inline-error,
.inline-success,
.inline-warning {
    padding: 8px 20px;
    margin: 20px 0;
    width: 100%;
    border-radius: 8px;
    border: 1px solid;
    text-align: center;

    a,
    p {
        color: inherit !important;
    }

    p {
        margin-bottom: 25px;
    }
}

.inline-error {
    color: $error-dark;
    border-color: $error-default;
    background-color: $error-light;
}

.inline-success {
    color: $success-dark;
    border-color: $success-default;
    background-color: $success-light;
}

.inline-warning {
    color: $warning-dark;
    border-color: $warning-default;
    background-color: $warning-light;
}

.bold-text {
    font-weight: bold;
}

//PDF-Viewer
pdf-viewer {
    display: block;

    &.loaded {
        .ng2-pdf-viewer-container {
            position: relative;
        }
    }
}

.spacer {
    flex: 1 1 auto;
}

.both-direction-centered {
    display: grid;
    place-items: center center;
}
