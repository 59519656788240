$xs-max: 599px;
$sm-min: 600px;
$sm-max: 1023px;
$md-min: 1024px;
$md-max: 1439px;
$lg-min: 1440px;
$lg-max: 1919px;
$xl-min: 1920px;

// Usage:
// .example {
//   width: 1000px;
//   @include sm {
//     width: 500px;
//   }
// }

@mixin xs {
  @media (max-width: #{$xs-max}) {
      @content;
  }
}
@mixin ltesm {
  @media (max-width: #{$sm-max}) {
      @content;
  }
}
@mixin sm {
  @media (min-width: #{$sm-min}) and (max-width: #{$sm-max}){
      @content;
  }
}
@mixin smlg {
  @media (min-width: #{$sm-min}) and (max-width: #{$lg-max}){
      @content;
  }
}
@mixin gtesm {
  @media (min-width: #{$sm-min}) {
      @content;
  }
}
@mixin ltemd {
  @media (max-width: #{$md-max}) {
      @content;
  }
}
@mixin md {
  @media (min-width: #{$md-min}) and (max-width: #{$md-max}){
      @content;
  }
}
@mixin gtemd {
  @media (min-width: #{$md-min}) {
      @content;
  }
}
@mixin lg {
  @media (min-width: #{$lg-min}) and (max-width: #{$lg-max}){
      @content;
  }
}
@mixin gtelg {
  @media (min-width: #{$lg-min}) {
      @content;
  }
}
@mixin xl {
  @media (min-width: #{$xl-min}){
      @content;
  }
}

@media (max-width: #{$xs-max}) {

}

@media (min-width: #{$sm-min}) and (max-width: #{$sm-max}){

}

@media (min-width: #{$md-min}) and (max-width: #{$md-max}){

}

@media (min-width: #{$lg-min}) and (max-width: #{$lg-max}){

}

@media (min-width: #{$xl-min}){

}
