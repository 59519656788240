$primary100: #17161E;
$primary90: lighten($primary100,10%);
$primary80: lighten($primary100,20%);
$primary70: lighten($primary100,30%);
$primary60: lighten($primary100,40%);
$primary50: lighten($primary100,50%);
$primary40: lighten($primary100,60%);
$primary30: lighten($primary100,70%);
$primary20: lighten($primary100,80%);
$primary10: lighten($primary100,85%);

$black100: #25282B;
$black80: #52575C;
$black60: #A0A4A8;
$black40: #CACCCF;
$black20: #DBDDE0; 
$black10: #E8E8E8;
$black5: #F7F7F7;

$white-default: #FFFFFF;
$white-dark: #F7F9FB;

$warning-light: #FFF2D2;
$warning-default: #FFE4A5;
$warning-dark: #CC8400;

$success-default: #2AC769;
$success-dark: #128718;
$success-light: #D1F9C6;

$error-default: #FB4E4E;
$error-dark: #E93C3C;
$error-light: #FFC6C6;

$accent-default: #4F24FA;

@mixin bg-opacity($value) {
    background-color: rgba($color: $value, $alpha: 0.3);
}

.primary100 {
    color: $primary100;
}

.primary90 {
    color: $primary90;
}

.primary80 {
    color: $primary80;
}

.primary70 {
    color: $primary70;
}

.primary60 {
    color: $primary60;
}

.primary50 {
    color: $primary50;
}

.primary40 {
    color: $primary40;
}

.primary30 {
    color: $primary30;
}

.primary20 {
    color: $primary20;
}

.primary10 {
    color: $primary10;
}

.black100 {
    color: $black100;
}

.black80 {
    color: $black80;
}

.black60 {
    color: $black60;
}

.black40 {
    color: $black40;
}

.black20 {
    color: $black20;
}

.black10 {
    color: $black10;
}

.black5 {
    color: $black5;
}

.white-default {
    color: $white-default;
}

.white-dark {
    color: $white-dark;
}

.warning-default {
    color: $warning-default;
}

.warning-light {
    color: $warning-light;
}

.warning-dark {
    color: $warning-dark;
}

.success-default {
    color: $success-default;
}

.success-dark {
    color: $success-dark;
}

.success-light {
    color: $success-light;
}

.error-default {
    color: $error-default;
}

.error-dark {
    color: $error-dark;
}

.error-light {
    color: $error-light;
}

.accent-default {
    color: $accent-default;
}

.bg-primary100 {
    background-color: $primary100;
}

.bg-primary90 {
    background-color: $primary90;
}

.bg-primary80 {
    background-color: $primary80;
}

.bg-primary70 {
    background-color: $primary70;
}

.bg-primary60 {
    background-color: $primary60;
}

.bg-primary50 {
    background-color: $primary50;
}

.bg-primary40 {
    background-color: $primary40;
}

.bg-primary30 {
    background-color: $primary30;
}

.bg-primary20 {
    background-color: $primary20;
}

.bg-primary10 {
    background-color: $primary10;
}

.bg-black100 {
    background-color: $black100;
}

.bg-black80 {
    background-color: $black80;
}

.bg-black60 {
    background-color: $black60;
}

.bg-black40 {
    background-color: $black40;
}

.bg-black20 {
    background-color: $black20;
}

.bg-black10 {
    background-color: $black10;
}

.bg-black5 {
    background-color: $black5;
}

.bg-white-default {
    background-color: $white-default;
}

.bg-white-dark {
    background-color: $white-dark;
}

.bg-warning-light {
    background-color: $warning-light;
}

.bg-warning-dark {
    background-color: $warning-dark;
}

.bg-warning-default {
    background-color: $warning-default;
}

.bg-success-default {
    background-color: $success-default;
}

.bg-success-dark {
    background-color: $success-dark;
}

.bg-success-light {
    background-color: $success-light;
}

.bg-error-default {
    background-color: $error-default;
}

.bg-error-dark {
    background-color: $error-dark;
}

.bg-error-light {
    background-color: $error-light;
}

.bg-accent-default {
    background-color: $accent-default;
}

.fill-none {
    fill: none;
}

.fill-primary100 {
    fill: $primary100;
}

.fill-primary90 {
    fill: $primary90;
}

.fill-primary80 {
    fill: $primary80;
}

.fill-primary70 {
    fill: $primary70;
}

.fill-primary60 {
    fill: $primary60;
}

.fill-primary50 {
    fill: $primary50;
}

.fill-primary40 {
    fill: $primary40;
}

.fill-primary30 {
    fill: $primary30;
}

.fill-primary20 {
    fill: $primary20;
}

.fill-primary10 {
    fill: $primary10;
}

.fill-black100 {
    fill: $black100;
}

.fill-black80 {
    fill: $black80;
}

.fill-black60 {
    fill: $black60;
}

.fill-black40 {
    fill: $black40;
}

.fill-black20 {
    fill: $black20;
}

.fill-black10 {
    fill: $black10;
}

.fill-black5 {
    fill: $black5;
}

.fill-white-default {
    fill: $white-default;
}

.fill-white-dark {
    fill: $white-dark;
}

.fill-warning-light {
    fill: $warning-light;
}

.fill-warning-dark {
    fill: $warning-dark;
}

.fill-warning-default {
    fill: $warning-default;
}

.fill-success-default {
    fill: $success-default;
}

.fill-success-dark {
    fill: $success-dark;
}

.fill-success-light {
    fill: $success-light;
}

.fill-error-default {
    fill: $error-default;
}

.fill-error-dark {
    fill: $error-dark;
}

.fill-error-light {
    fill: $error-light;
}

.fill-accent-default {
    fill: $accent-default;
}
