@import "palette";

.liquid-light-theme {
    .sidenav-tooltip {
        @include ltesm {
            display: none;
        }
    }

    a {
        color: $primary100;

        &:not(.mat-tab-link) {
            font-size: inherit;
            font-weight: inherit;
        }

        i:hover {
            color: inherit;
        }
    }

    .declined,
    .warn {
        color: $warning-dark !important;

        &.round,
        &.oval {
            background-color: $primary10 !important;
        }
    }

    .error {
        color: $error-default !important;

        &.round,
        &.oval {
            background-color: $primary10 !important;
        }
    }

    .secondary {
        color: $accent-default !important;

        &.round,
        &.oval {
            background-color: $primary10 !important;
        }
    }

    .approved,
    .success {
        color: $success-dark !important;

        &.round,
        &.oval {
            background-color: $primary10 !important;
        }
    }

    .primary,
    .checked {
        color: $primary100 !important;

        &.round,
        &.oval {
            background-color: $primary10 !important;
        }
    }

    .accent {
        color: $accent-default !important;

        &.round,
        &.oval {
            background-color: $primary10 !important;
        }
    }

    .info {
        color: $primary40 !important;

        &.round,
        &.oval {
            background-color: $primary10 !important;
        }
    }

    .blue {
        color: $primary50;

        &.round,
        &.oval {
            background-color: $primary10;
        }
    }

    .green {
        color: $success-dark;

        &.round,
        &.oval {
            background-color: $primary10;
        }
    }

    .highlight {
        font-weight: bold;
        text-decoration: underline;
    }

    button {
        &.menu-item {
            &.mat-menu-item:hover {
                color: $primary50;
                background-color: $primary10;
            }
        }
    }

    i-feather {
        flex-shrink: 0;
    }

    .mat-progress-bar.work-order-progress-bar {

        .mat-progress-bar-background,
        .mat-progress-bar-buffer {
            background-color: $black10;
            fill: $black10;
        }

        &.work-order-progress-bar-warn {
            .mat-progress-bar-fill::after {
                background-color: $error-dark;
            }
        }
    }
}
