@import 'breakpoints';
@import 'palette';

@mixin drop-shadow {
    box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
}

@mixin drop-shadow-dark {
    box-shadow: 0px 4px 16px rgba($color: $black100, $alpha: 0.08);
}

@mixin logo-window-size {
    width: 227px;
    height: 80px;
}

@mixin large-icon-size {
    height: 24px;
    width: 24px;
}

@mixin table-grid-container {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: auto auto 1fr;
    grid-template-areas: "table-grid-heading" "table-grid-actions" "table-grid-content";
    grid-gap: 10px;
    @include ltesm {
        overflow: auto;
        margin: 20px 0;
    }
}

@mixin table-grid-header-container {
    display: flex;
    position: -webkit-sticky;
    position: sticky;
    left: 0px;
    width: 100%;
    max-width: calc(96vw - 260px);
    justify-content: space-between;
    grid-area: table-grid-heading;
    padding-left: 32px;
    @include ltesm {
        max-width: 94vw;
    }
    @include xs {
        padding-left: 20px;
        flex-direction: column;
    }
}

@mixin table-grid-heading {
    grid-area: table-grid-heading;
    @include ltesm {
        max-width: 100vw;
    }
}

@mixin table-grid-actions {
    grid-area: table-grid-actions;
    @include ltesm {
        max-width: 100vw;
    }
}

@mixin table-grid-actions-sticky {
    @include table-grid-actions;
    position: -webkit-sticky;
    position: sticky;
    left: 0;
    top: 0;
    width: 100%;
    max-width: calc(96vw - 260px);
    padding-left: 32px;
    @include xs {
        padding-left: 20px;
    }
}

@mixin table-content {
    max-width: 100%;
}

@mixin table-grid-content {
    grid-area: table-grid-content;
    padding-left: 32px;
    min-width: 100%;
    @include xs {
        margin: 0;
        padding: 0 12px;
    }
}

@mixin spin-animation {
    animation: spin-animation 1s infinite;
    animation-timing-function: linear;
}

@keyframes spin-animation {
    0% {
        transform: rotate(0deg);
        transform-origin: center;
    }
    100% {
        transform: rotate(360deg);
    }
}

@mixin summary-page {
    flex: 1;
    overflow-x: hidden;
}

@mixin module-box {
    margin: 32px 32px 0 32px;
}

@mixin no-card {
    border-radius: 0px;
    box-shadow: 0px 0px 0px rgba($color: $black100, $alpha: 0.1);
}

@mixin default-card {
    border-radius: 8px;
    box-shadow: 0px 0px 2px rgba($color: $black100, $alpha: 0.1);
}

@mixin status-avatar {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 12px;

    img {
        width: 100px;
        height: 100px;
        border-radius: 50%;
    }

    app-chip-list {
        margin-top: -24px;
    }
}

@mixin lqd-counter {
    list-style: none;
    margin: 0;
    counter-reset: lqd-counter;
    @include xs {
        padding-inline-start: 0;
    }
    li {
        counter-increment: lqd-counter;
        padding-left: 58px;
        padding-bottom: 12px;
        position: relative;
        &::before {
            position: absolute;
            top: 0;
            left: 0;
            height: 34px;
            min-width: 34px;
            border-radius: 50%;
            content: counter(lqd-counter);
            background: $white-default;
            font-weight: bold;
            font-size: 16px;
            line-height: 34px;
            border-radius: 50%;
            display: inline-block;
            color: $black100;
            border: 4px solid $primary20;
            text-align: center;
            margin-right: 0.5rem;
        }
        @include xs {
            padding-left: 28px;
            &::before {
                height: 14px;
                min-width: 14px;
                border-width: 1px;
                font-size: 10px;
                line-height: 14px;
                top: 12px;
            }
        }
    }
}

@mixin ellipsis-overflow {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

// Used in data views with grid+table and actions (filter, sort)
// Usage where ".page-actions" includes this mixin:
// <div class="page-actions">
//     <app-filter-accordion></app-filter-accordion>
//     <div class="toggles">
//         <button ...(for grid toggle)></button>
//         <button ...(for table toggle)></button>
//     </div>
// </div>
@mixin page-actions-with-mode {
    flex: 1;
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;
    margin-left: 32px;

    @include xs {
        margin: 0 12px;
        justify-content: unset;
    }
    button {
        &:first-child:not(:only-child) {
            margin-right: 20px;
        }
    }
}
// use on :host {} of whatever component hosts app-data-page-template (rendered in a router-outlet)
// this inclueds the .toggles class to use with above example
@mixin data-page-template-container {
    width: 100%;
}

@mixin data-page-card-paginator {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 12px 0 18px 0;
    button {
        margin: 0 4px;
    }
}

@mixin workflow-info-step {
    font-size: 16px !important;
    list-style-type: none;

    .workflow-info-item {
        display: grid;
        grid-template-columns: 175px 1fr;
        align-items: center;
        margin-top: 10px;

        div:first-of-type {
            margin-right: 10px;
        }
    }
}

@mixin settings-table-template-container {
    width: 100%;
    margin: 0;
}
