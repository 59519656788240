@import "mixins";
@import "breakpoints";
@import "palette";

.liquid-light-theme {
    .wallet-cards {
        .mat-radio-label-content {
            padding-left: 32px;
        }

        .mat-form-field-wrapper {
            padding-bottom: 26px;
        }
    }

    .site-header {
        .nav-userHeader {
            i-feather {
                color: $primary100;
            }
        }

        .nav-search {
            .mat-form-field-wrapper {
                overflow: hidden;
                background: $white-default;
                border-radius: 8px;
                padding: 0;
                border: none;
                @include drop-shadow;
            }

            .mat-form-field-label-wrapper,
            .mat-form-field-underline {
                display: none;
            }

            .mat-form-field-flex {
                padding: 0;
                background-color: $white-default;
            }

            .mat-form-field-suffix {
                align-self: center;
                margin-right: 15px;
                margin-left: 0;

                @include xs {
                    width: 40px;
                    height: 40px;
                    left: 0;
                    right: 0;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    background-color: $white-default;
                    margin: 0;
                    position: absolute;
                }

                i-feather {
                    font-size: 24px;
                    color: $primary100;
                }
            }

            .search-field {
                @include xs {
                    &:focus-within {
                        .mat-form-field-suffix {
                            top: 0;
                            left: 0;
                        }
                    }
                }
            }

            .mat-form-field-infix {
                padding: 0;
                border: none;
                width: auto;

                input {
                    margin: 0;
                    font-size: 16px;
                    padding: 0 15px;
                    line-height: 50px;
                    color: $black80;
                    caret-color: $black80;

                    @include xs {
                        line-height: 40px;
                    }
                }
            }
        }
    }

    /* SIDE NAVIGATION */
    $expandedMargin: 260px;
    $collapsedMargin: 76px;

    .app {
        .mat-sidenav-content.app-content {
            overflow: hidden;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;
            align-content: flex-start;
        }

        .mat-drawer.mat-sidenav {
            &.mat-drawer-opened {
                width: 74px;
            }
        }

        .content {
            .sidenav-container {
                //background: url("/assets/img/backgrounds/WavesBG.png") no-repeat center center fixed;
                // background-size: cover;

                // @include sm {
                //     background-size: contain;
                //     background-position: bottom;
                // }

                .mat-sidenav-content {
                    .router-outlet {
                        width: 100%;
                    }

                    &.guest-page {
                        margin-left: 0px !important;

                        .router-outlet {
                            height: 100%;
                            width: 100vw !important;
                        }
                    }

                    @include gtemd {
                        transition: margin 250ms ease-in-out;

                        @media print {
                            margin-left: 0px !important;
                        }

                        .router-outlet {
                            width: 100%;
                        }
                    }

                    @include ltesm {
                        margin-left: 0 !important;

                        .router-outlet {
                            width: 100vw;
                        }
                    }
                }

                .sidenav-header {

                    .mat-form-field .mat-form-field-flex,
                    .mat-form-field-flex {
                        background-color: transparent !important;
                        padding: 0 10px;
                    }
                }

                .sidenav-nav {
                    transition: width 250ms ease-in-out;

                    a.expand {
                        .mat-icon {
                            color: $black80;

                            &:hover {
                                color: $black80 !important;
                            }
                        }
                    }

                    .mat-form-field {
                        .mat-form-field {
                            font-size: 14px;
                        }
                    }

                    .mat-drawer-inner-container {
                        display: flex;
                        flex-direction: column;
                    }
                }
            }
        }
    }

    .containers-dashboard {
        .info-container {
            .highlight {
                box-shadow: 2px 0px 14px $primary50;
            }
        }
    }

    .container-editor {
        .line-items {
            .item {
                .field {
                    .details {
                        color: $primary70;
                    }
                }
            }
        }
    }

    .invitee {
        border: 1px solid $primary20;
    }

    .selected-invite-option {
        color: $accent-default;
    }

    .unselected-invite-option {
        color: $primary40;
    }

    .placeholder {
        color: $primary40;
    }

    .email {
        border: 1px solid $primary40;
    }

    .search {
        border: 1px solid $primary40;

        .search-results {
            .result-row {
                .result {
                    border-bottom: 1px dotted $primary20;
                }

                &:hover {
                    background-color: $primary10;
                }

                .result-selected {
                    background-color: $primary10;
                }

                .not-selectable {
                    color: $primary40;

                    &:hover {
                        background-color: $primary10;
                    }
                }
            }
        }
    }

    .document-sign,
    .questionnaire,
    .container-with-sidenav {

        .field-list,
        .section-list,
        .sidenav {
            .add-row {
                color: $success-default;
            }

            .list-container {

                .field-row,
                .list-row {
                    &:hover {
                        background-color: $primary10;

                        .field-info,
                        .select,
                        .list-row-item {
                            color: $primary50;
                        }
                    }
                }

                .select-row {
                    .new {
                        color: $success-default;
                    }

                    &:hover {
                        background-color: $primary10;

                        .select {
                            color: $primary50;
                        }
                    }

                    .select.selected {
                        background-color: $primary10;
                        color: $primary50;
                    }
                }

                .selected {
                    background-color: $primary10;

                    .field-info,
                    .select,
                    .list-row-item {
                        color: $primary50;
                    }
                }
            }
        }
    }

    .tracking-doc-container {
        .tracking-entry {
            background-color: $primary10;
        }
    }

    .step {
        .step-content {
            border: 1px solid $primary60;
        }

        .edit-step-content {
            border: 1px solid $primary60;
        }
    }

    .overlay {
        .overlay-window {
            .overlay-window-item {
                &:hover {
                    color: $primary50;
                    background-color: $primary10;
                }
            }
        }
    }

    .section {
        .hero {
            .image-frame {
                .image-offset {
                    background-color: $accent-default;
                }
            }
        }

        .two-box {
            .compare-box {
                .compare-header {
                    background-color: $accent-default;
                }
            }
        }
    }

    .onboarding-processes {
        .processes-container {
            .process-card {
                .card-content {
                    .front {
                        .action-menu {
                            .menu-item {
                                &:hover {
                                    color: $primary50;
                                    background-color: $primary10;
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    .page-contents {
        .contents-list {
            .list-item {
                .item-data {
                    .item-data-main {
                        .list-item-status {
                            &.accent {
                                color: $accent-default;
                            }

                            &.warn {
                                color: $warning-default;
                            }

                            &.primary {
                                color: $primary50;
                            }
                        }
                    }
                }
            }
        }

        .contents-list {
            .list-item-container {
                .list-item {
                    background-color: $white-default;

                    &.edit-step-content {
                        .step-name {
                            .required {
                                .mat-checkbox-inner-container {
                                    color: $accent-default;
                                }
                            }
                        }
                    }
                }

                .step-count {
                    .count {
                        color: $accent-default;
                        border: 3px solid $primary100;
                    }
                }
            }
        }
    }

    /* COA */
    .overflow-cell-coa {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;

        @include sm {
            max-width: inherit;
        }
    }

    /* INVOICE SELECT */
    .invoice-form {
        .row {
            .col {
                &.invoice-info {
                    .mat-select-trigger {
                        height: 1.33em;
                    }
                }
            }
        }
    }

    /* BRANDING */
    .organization-settings-branding {
        .avatar-container {
            .img-container {
                .mat-icon-button {
                    background-color: $white-default;
                    @include drop-shadow;

                    &.small {
                        height: 20px;
                        width: 20px;
                        line-height: 20px;
                        box-shadow: none;
                    }
                }
            }
        }
    }

    .business-info-nav-mobile {
        .mat-form-field-wrapper {
            padding-left: 1.25em;
            padding-right: 1.25em;
            padding-bottom: 0;

            .mat-form-field-outline-start,
            .mat-form-field-outline-gap,
            .mat-form-field-outline-end {
                border-color: transparent;
            }
        }

        .mat-input-element {
            color: $primary100;
            font-size: 14px;
            letter-spacing: 0.1px;
            line-height: 22px;
            padding-top: 0;
        }

        .mat-focused .mat-form-field-infix::after {
            color: $primary100;
        }
    }

    .user-settings-dialog {

        .user-settings-dialog-edit-personal-information,
        .user-settings-dialog-change-password {
            form {
                .mat-form-field {
                    .mat-form-field-flex {
                        padding: 0;
                        background-color: $white-default;
                    }

                    .mat-form-field-infix {
                        width: 100%;

                        input.mat-input-element {
                            font-family: Poppins;
                            font-style: normal;
                            font-weight: normal;
                            font-size: 16px;
                            line-height: 24px;
                            color: $black100;
                        }

                        .mat-form-field-label-wrapper label {
                            padding-top: 0;
                            color: $black60;
                            font-size: 16px;
                            line-height: 24px;
                        }
                    }
                }
            }
        }

        .user-settings-dialog-change-password {
            .mat-slider {
                .mat-slider-track-background {
                    background-color: $black20;
                    border-radius: 2px;
                }

                .mat-slider-track-fill {
                    background-color: $primary80;
                    border-radius: 2px;
                }

                .mat-slider-thumb-container {
                    .mat-slider-thumb {
                        background-color: $white-default;
                        border: 2px solid $primary80;
                    }
                }
            }
        }
    }

    .mat-ink-bar {
        background-color: $accent-default !important;
    }

    .subnav-mobile {
        .mat-form-field-wrapper {
            padding-left: 1.25em;
            padding-right: 1.25em;
            padding-bottom: 0;

            .mat-form-field-outline-start,
            .mat-form-field-outline-gap,
            .mat-form-field-outline-end {
                border-color: transparent;
            }
        }

        .mat-input-element {
            color: $primary100;
            font-size: 14px;
            letter-spacing: 0.1px;
            line-height: 22px;
            padding-top: 0;
        }

        .mat-focused .mat-form-field-infix::after {
            color: $primary100;
        }
    }

    .avatar-management-dialog {
        .mat-dialog-content {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
        }

        .mat-button.button-transparent {
            font-weight: normal;
            font-size: 14px;
            line-height: 18px;
            display: flex;
            align-items: center;
            text-align: center;
            letter-spacing: 0.2px;
            background-color: transparent;
            margin-right: 20px;
            color: $primary100;
        }

        .mat-raised-button {
            font-weight: normal;
            background-color: $accent-default;
            color: $white-default;
            border-radius: 8px;
        }
    }

    .state-zip-container {
        .mat-form-field-infix {
            width: 100%;
        }
    }

    /* Clients / Vendors */
    .clients-home,
    .vendors-home {
        .action-button {
            &:not(:last-child) {
                margin-right: 20px;
            }
        }

        .table-container {
            td.check-button.button-column {
                button:not(:disabled) {
                    i-feather {
                        color: $primary100 !important;

                        &.selected {
                            fill: $primary100 !important;
                        }
                    }
                }
            }
        }

        .mat-chip-list {
            &:focus {
                outline: none !important;
            }
        }

        .mat-chip {
            &:focus {
                outline: none !important;
            }
        }

        .team-filters-overlay-panel {
            width: 100%;
        }
    }

    td.mat-cell {
        &.table-low-contrast {
            color: $black60;
        }
    }

    /* Clients / Vendors */
    .clients-home,
    .vendors-home {
        app-table.table {
            .mat-table {
                width: 100%;
            }
        }
    }

    /* Select Business Function */
    .business-descriptions {
        button {
            .mat-button-wrapper {
                flex: 1;
                flex-direction: column;
            }
        }
    }

    .mat-tab-group.no-border .mat-tab-header {
        border-bottom-style: none !important;
    }

    .no-hover .mat-button-focus-overlay {
        opacity: 0 !important;
    }

    /* Placeholder deliverable list */
    app-deliverable-list.deliverable-list {

        .list-item.view-mode,
        .list-item.edit-mode {
            box-shadow: none !important;
            padding: 0 !important;
            width: 100% !important;
        }
    }

    /* Notification Chips */
    .notification-container {
        margin: 15px;

        mat-chip.mat-chip {
            height: auto !important;
        }

        .mat-chip {
            width: 100%;

            .chip-value {
                max-width: 100% !important;
                width: 100% !important;
                white-space: break-spaces;
            }
        }
    }

    /* Create Work Order */
    .create-work-order-workflow {
        .services-and-deliverables {
            .mat-expansion-panel-content {
                .mat-expansion-panel-body {
                    div.workflow-group-content.single-step {
                        margin: 24px 0 !important;

                        .inline-error {
                            width: auto;
                            margin: 0 75px;
                        }

                        .inline-confirm {
                            margin: 24px 150px;
                        }

                        .step-footer {
                            .button-container {
                                margin-right: 24px;
                            }
                        }
                    }
                }
            }
        }
    }

    /* Invoice Detail */
    .mat-tab-group.invoice-error {
        .mat-tab-header {
            .mat-tab-label-container .mat-tab-list {
                .mat-ink-bar {
                    background-color: $error-dark !important;
                }
            }
        }
    }

    .invoice-cta {
        .pay-invoice {
            .form {
                .mat-radio-label-content {
                    white-space: initial !important;
                    line-height: initial !important;
                }
            }
        }
    }

    /* Tag Group Colllapsed tooltip */
    .tag-collapsed-tooltip {
        white-space: pre-line;
        word-wrap: normal;
        border-radius: 8px;
        border: 1px solid $primary30;
        color: $primary100;
        font-size: 12px;
        line-height: 14px;
        text-align: center;
        max-width: 200px;
        background-color: $white-default;
    }

    // app-data-page-template
    // TODO: move these styles to a new partial
    app-data-page-template {
        .data-header-actions {
            display: flex;
            align-items: center;
        }

        .data-page-actions {
            @include table-grid-actions-sticky;
            display: flex;
            align-items: center;
            flex-wrap: wrap;

            .toggles {
                flex: 1;
                display: flex;
                justify-content: flex-end;
            }
        }

        .data-page-content {
            @include table-grid-content;
            min-width: fit-content;

            &.loading {
                display: flex;
                justify-content: center;
            }
        }
    }

    app-action-page-template.module-container.settings-table-module {
        position: relative;
        // TODO: https://poweredbyliquid.atlassian.net/browse/LS-1056
        // if this padding-right becomes impossible, then use this workaround. otherwise remove comment
        // padding-right: 0;
        overflow-x: unset;

        .module-contents {
            overflow-x: unset;

            .subnav-content-container {
                overflow-x: unset;

                .subnav-content {
                    overflow-x: unset;
                }
            }
        }
    }

    app-settings-table-template {
        @include table-grid-container;
        grid-template-columns: 100%;
        overflow-x: unset;
        overflow-y: unset;

        @include xs {
            margin: 0;
            overflow-x: hidden;
            display: block;

            .data-table {
                .lqd-paginator {
                    .mat-paginator-outer-container {
                        .mat-paginator-container {
                            padding: 0;
                        }
                    }
                }
            }
        }

        .header-actions {
            display: flex;
            flex-wrap: wrap;
            justify-content: flex-end;

            @include xs {
                display: flex;
                flex-direction: column;
                align-items: center;
            }
        }

        .nav-content {
            overflow-x: unset;
            overflow-y: unset;

            .mat-tab-body-wrapper {
                overflow-x: unset;
                overflow-y: unset;
                max-width: 100%;

                .mat-tab-body {
                    overflow-x: hidden;

                    &.mat-tab-body-active {
                        overflow-x: auto;
                        max-width: 100%;
                        overflow-y: unset;
                    }

                    .mat-tab-body-content {
                        position: relative;
                        margin: 0;
                        padding: 0;
                        overflow-x: unset;
                        overflow-y: unset;

                        >.table-container {
                            @include table-grid-content;
                            max-width: 100%;
                            padding-left: 0;

                            @include xs {
                                margin: 0;
                                padding: 0;
                            }
                        }
                    }
                }
            }
        }

        .page-content {
            @include table-grid-content;
            padding: 0;

            @include xs {
                margin: 0;
                padding: 0;
            }
        }
    }

    // Project Links
    .project-links-work-order-summary-dialog {
        .mat-dialog-container {
            padding: 0 !important;
        }
    }

    // Contacts
    .contact-search-option {
        padding: 5px !important;
        height: unset;
        line-height: unset;
    }
}
