@import 'breakpoints';

.liquid-light-theme {
    
    .mat-dialog-container {
        margin: auto;
        min-height: 225px;
        @include ltesm {
            max-width: 90vw;
        }
    }

    .mat-dialog-content {
        max-height: none;
        overflow: unset;
    }

    .button-row {
        display: flex;
        justify-content: flex-end;
        button {
            margin-left: 10px;
        }

        @include xs {
            flex-direction: column-reverse;
            align-items: center;
            button {
                margin-left: 0;
                margin-top: 10px;
            }
        }
    }
    
    mat-dialog-actions {
        .cancel-alert {
            margin: 0 auto;
            width: 80%;         
        p {
            margin: 0;
        }
        @include ltesm {
            width: 50%;
        }
        }
    }
}
